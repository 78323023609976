import React, { Component } from 'react';

import PropTypes from 'prop-types';

import Header from './template/Header.js';
import Content from './template/Content.js';
import Footer from './template/Footer.js';

import * as dataPreload from '../assets/images/preload.json';

import $ from 'jquery';

import Lottie from 'react-lottie';

const langText = {
  es: [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Edificio Insigne, Oficina 1703, Avenida Las Magnolias, Colonia San Benito, San Salvador.",
    "VER DIRECCIÓN",
    "Estados Unidos",
    "Copyright © 2020 Derechos Reservados",
    "",
  ],
  en: [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Edificio Insigne, Oficina 1703, Avenida Las Magnolias, Colonia San Benito, San Salvador.",
    "SEE ADDRESS",
    "United States",
    "Copyright © 2020 All Rights Reserved",
    "",
  ],
}



class App extends Component {
  constructor(props){
    super(props);

    this.state = {
        languages: this.urlLanguages(),
    }
  }

  static propTypes = {
    children : PropTypes.object.isRequired
  }

  componentDidMount() {
        
    // CURSOR
    var xMousePos = 0;
    var yMousePos = 0;
    var lastScrolledLeft = 0;
    var lastScrolledTop = 0;
    var windowW = $(window).width();

    function captureMousePosition(event){
        xMousePos = event.pageX;
        yMousePos = event.pageY;
        window.status = "x = " + xMousePos + " y = " + yMousePos;
    }

    $(document).mousemove(function(event) {
        captureMousePosition(event);

        $("#follow-cursor").css({ top: (yMousePos - 45), left: (xMousePos - 45) });

        $("#modal-project").css({ top: (yMousePos - 45), left: (xMousePos - 45) });
        
        // if(!$(".app").hasClass("load"))
        //     $("#overlay-gradient").css('background-position', (xMousePos - (windowW / 2))+'px 50%');
    })  

    $(window).scroll(function(event) {
        if(lastScrolledLeft !== $(document).scrollLeft()){
            xMousePos -= lastScrolledLeft;
            lastScrolledLeft = $(document).scrollLeft();
            xMousePos += lastScrolledLeft;
        }
        
        if(lastScrolledTop !== $(document).scrollTop()){
            yMousePos -= lastScrolledTop;
            lastScrolledTop = $(document).scrollTop();
            yMousePos += lastScrolledTop;
        }
        window.status = "x = " + xMousePos + " y = " + yMousePos;

        $("#follow-cursor").css({ top: (yMousePos - 45), left: (xMousePos - 45) });
        
        $("#modal-project").css({ top: (yMousePos - 45), left: (xMousePos - 45) });
    });

    $(".hoverAction").hover(function () {
      $("#follow-cursor").addClass("hoverAction");
    }, function () {
        $("#follow-cursor").removeClass("hoverAction");
      }
    );

    $(".hoverDark").hover(function () {
      $("#follow-cursor").addClass("hoverDark");
    }, function () {
        $("#follow-cursor").removeClass("hoverDark");
      }
    );

    $(".hoverClose").hover(function () {
      $("#follow-cursor").addClass("hoverClose");
    }, function () {
        $("#follow-cursor").removeClass("hoverClose");
      }
    );

    $(".hoverHidden").hover(function () {
      $("#follow-cursor").addClass("hoverHidden");
    }, function () {
        $("#follow-cursor").removeClass("hoverHidden");
      }
    );


    var nowPlaying = "none";
    $("#modal-project").find(".contain").find(".link").find("iframe[link=insigne]").hover(function () {
        nowPlaying = $(this).attr('src');
        $(this).find('iframe').attr('src',nowPlaying+'&autoplay=1');
      }, function () {
        $(this).attr('src',nowPlaying);
      }
    );

  }

  urlLanguages(){
    var url = (window.location.href).split("/");
    var lang = null;
    
    console.log(url.length);

    if(url.length > 4){
        lang = String(url[3]);
    }else{
        lang = "es";
    }
    
    return lang;
  }

  render(){
    const { children } = this.props;

    const defaultOptionsP = {
      loop: true,
      autoplay: true, 
      animationData: dataPreload.default,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    }; 

    return (
      <div className="app">
        
        <div className="preload easeOutCubic-f active">
          <div className="object"><Lottie options={defaultOptionsP} isStopped={false} isPaused={false} /></div>
        </div>

        <div id="menu">
          <div className="content center">
            <div className="box">
              <div className="item">
                <h3>What we do</h3>
                <a href={window.innerWidth > 1024 ? process.env.PUBLIC_URL+"/#services" : process.env.PUBLIC_URL+"/#branding" } section="services" service="branding"></a>
                <a href={window.innerWidth > 1024 ? process.env.PUBLIC_URL+"/#services" : process.env.PUBLIC_URL+"/#development" } section="services" service="development"></a>
                <a href={window.innerWidth > 1024 ? process.env.PUBLIC_URL+"/#services" : process.env.PUBLIC_URL+"/#socialmedia" } section="services" service="socialmedia"></a>
              </div>
              <div className="item">
                <a href={process.env.PUBLIC_URL+"/#work"} section="work">Our Work</a>
              </div>
              <div className="item">
                <a href={process.env.PUBLIC_URL+"/#team"} section="team">Our Team</a>
              </div>
              <div className="item">
                <a href={process.env.PUBLIC_URL+"/#team"} className='join open-modal-join' section="team">Job Application</a>
              </div>
              <div className="item">
                <a href={process.env.PUBLIC_URL+"/#contact"} section="contact">Contact</a>
              </div>
            </div>
            <div className="information"><h3>HQ <strong>El Salvador</strong></h3><a href="tel:+ (503) 2541-4232" className="tel">+ (503) 2541-4232</a>
            <p>{langText[this.state.languages][16]}</p>
            <a href="https://www.waze.com/ul?place=ChIJTTrp8i8wY48RaRnozobX1mM&amp;ll=13.69245890%2C-89.23828200&amp;navigate=yes" className="waze hoverAction" target="_blank" rel="noopener noreferrer"><i className="fab fa-waze"></i>{langText[this.state.languages][17]}</a>
            <ul><li>{langText[this.state.languages][18]}</li><li>Guatemala</li><li>Panamá</li></ul>
            </div>
          </div>

          <div className="languages">
            <a href="https://lafabrica.agency/" rel="noopener noreferrer">ES</a>
            <i className="fas fa-circle"></i>
            <a href="https://lafabrica.agency/en/" rel="noopener noreferrer">EN</a>
          </div>

        </div>

        <Header />
        <Content page={children} />
        <Footer />
      </div>
    )
  }
  
}

export default App;
