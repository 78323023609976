import React, { Component } from 'react';
// import {Link } from "react-router-dom";
class Footer extends Component {
    render(){
        return (
            <footer className="hoverActionn" hover="dark">
                <div id="social-media">
                    <a href="https://api.whatsapp.com/send?phone=+50325414232" target="_blank" rel="noopener noreferrer"><i className="fab fa-whatsapp"></i></a>
                    <a href="https://www.facebook.com/lafabricadigitalagency/" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook"></i></a>
                    <a href="https://www.instagram.com/lafabricadigitalagency/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
                    <a href="https://www.linkedin.com/company/lafabricadigitalagency/" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in"></i></a>
                    <a href={process.env.PUBLIC_URL+"/#team"} className='join open-modal-join' id="open-modal-join-ft"><i className="fas fa-briefcase"></i></a>
                    <div className="languages">
                        <i className="fas fa-flag"></i>
                        <div className="lang">
                            <a href="https://lafabrica.agency/" rel="noopener noreferrer">ES</a>
                            <i className="fas fa-circle"></i>
                            <a href="https://lafabrica.agency/en/" rel="noopener noreferrer">EN</a>
                        </div>
                    </div>
                </div>
                <div id="chat"><a href="http://m.me/lafabricadigitalagency" target="_blank" rel="noopener noreferrer"><div className="ico"><i className="fab fa-facebook-messenger"></i></div></a></div>
                <h5 className="copyright">© Copyright 2019. All Rights Reserved.</h5>
            </footer>
        )
    }
}

export default Footer;