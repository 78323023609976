import React, { Component } from 'react';
import PropTypes from 'prop-types'

class Content extends Component{
    static propTypes = {
        page : PropTypes.object.isRequired
    }
    render(){
        const { page }  = this.props;
        return (
            <section>{page}</section>
        )
    }
}

export default Content;