import React, { Component } from 'react';
import $ from "jquery";

import Lottie from 'react-lottie';

import * as dataBranding from '../../assets/images/services/branding/data.json';

import * as dataDevelopment from '../../assets/images/services/development/data.json';

import * as dataSocialmedia from '../../assets/images/services/socialmedia/data.json';

import videoFull from '../../assets/images/videofull.mp4';

import videoMb from '../../assets/images/videomovil.mp4';

import OwlCarousel from 'react-owl-carousel2';


import pmob1 from '../../assets/images/works/projects/mobile/atlantida.jpg';
import pmob2 from '../../assets/images/works/projects/mobile/coopertires.jpg';
import pmob3 from '../../assets/images/works/projects/mobile/repsol.jpg';
import pmob4 from '../../assets/images/works/projects/mobile/uni.jpg';
import pmob5 from '../../assets/images/works/projects/mobile/insigne.jpg';
import pmob6 from '../../assets/images/works/projects/mobile/cakelab.jpg';
import pmob7 from '../../assets/images/works/projects/mobile/relajo.jpg';
import pmob8 from '../../assets/images/works/projects/mobile/diseno.jpg';
import pmob9 from '../../assets/images/works/projects/mobile/fyo.jpg';
import pmob10 from '../../assets/images/works/projects/mobile/glanz.jpg';
import pmob11 from '../../assets/images/works/projects/mobile/villavela.jpg';
import pmob12 from '../../assets/images/works/projects/mobile/kawaki.jpg';


import pdek1 from '../../assets/images/works/projects/atlantida.jpg';
import pdek2 from '../../assets/images/works/projects/coopertires.jpg';
import pdek3 from '../../assets/images/works/projects/repsol.jpg';
import pdek4 from '../../assets/images/works/projects/uni.jpg';
import pdek5 from '../../assets/images/works/projects/insigne.jpg';
import pdek6 from '../../assets/images/works/projects/cakelab.jpg';
import pdek7 from '../../assets/images/works/projects/relajo.jpg';
import pdek8 from '../../assets/images/works/projects/diseno.jpg';
import pdek9 from '../../assets/images/works/projects/fyo.jpg';
import pdek10 from '../../assets/images/works/projects/glanz.jpg';
import pdek11 from '../../assets/images/works/projects/villavela.jpg';
import pdek12 from '../../assets/images/works/projects/kawaki.jpg';

const options = {
    items: 1,
    loop:true,
    stagePadding: 50,
    responsive:{
        0:{
            items:1
        },
        500:{
            items:2
        },
        800:{
            items:3
        },
        1000:{
            items:4
        }
    }
};

const optionsAll ={
    items: 1,
    loop: false,
    autoplay: false,
    stagePadding: 0,
    autoHeight: true,
    autoHeightClass: 'owl-height',
    URLhashListener: true
}

const services = [
    ["branding","Cada marca tiene una personalidad intrínseca, nuestro trabajo es descubrirla, darle vida y crearle una identidad apegada a los valores de cada empresa para tener una comunicación coherente y homogénea.","<li>• Diseño y refrescamiento de marca </li><li>• Identidad e imagen corporativa </li><li>• Campañas digitales </li><li>• Diseño de empaques, rotulación, etc.</li>","#FF5C3E","branding"],
    ["development","Creamos plataformas enfocadas en la experiencia de los usuarios, para incrementar ventas y crear relaciones de valor a largo plazo.", "<li>• Desarrollo de sitios web, eCommerce.</li><li>• Sistemas a la medida.</li><li>• Alojamiento web, correos corporativos.</li><li>• Aplicaciones móviles.</li><li>• Newsletter.</li>","#8DE160","development"],
    ["socialmedia", "Utilizamos estrategias de comunicación basadas en las últimas tendencias, logrando potenciar la comunicación de tu marca con los consumidores.","<li>• Manejo de redes sociales.</li><li>• Creación de contenido.</li><li>• Gestión de la comunidad en línea.</li><li>• Estrategia de comunicación digital.</li>","#2BA7DE","social media"],
];

const servicesENG = [
    ["branding","Each brand has it’s own personality, our job is to discover it, bring it to life and create an identity that is congruent with each company, to have a homogeneous and coherent communication.","<li>• Branding and rebranding. </li><li>• Corporate image. </li><li>• Digital campaign.  </li><li>• Packaging design, labeling, among other.</li>","#FF5C3E","branding"],
    ["development","We produce platforms focused on user experience to increase sales and form long term relationships.","<li>• Website design and development, eCommerce.</li><li>• Custom made systems.</li><li>• Hosting and corporate email.</li><li>• Mobile apps.</li><li>• Newsletter.</li>","#8DE160","development"],
    ["socialmedia","We design communication strategies based on the latest trends, managing to enhance the communication of your brand with consumers.","<li>• Social Media.</li><li>• Content creation.</li><li>• Online community management.</li><li>• Digital communication strategy.</li>","#2BA7DE","social media"],
];

const projects = [
    ["development","atlantida","Atlantida Capital", "Diseño, creación y desarrollo de sitio web informativo, optimizado para todos los dispositivos incluyendo móviles (Responsive Design), con gestor de contenido y optimización de palabras claves en motores de búsqueda.","#D62934","1"],
    ["socialmedia","coopertires","Coopertires", "Planificación e implementación de estrategia de contenido para redes sociales, creación de línea gráfica, desarrollo de cronogramas, publicaciones, manejo de la comunidad en línea y de campañas digitales.","#1784CB","1"],
    ["socialmedia","repsol","Repsol", "Planificación e implementación de estrategia de contenido para redes sociales, creación de línea gráfica, desarrollo de cronogramas, publicaciones, manejo de la comunidad en línea y de campañas digitales.","#FAA228","2"],
    ["development","uni","Uni", "Diseño, creación y desarrollo de sitio web instructivo, presenta toda la información necesaria para el uso de la aplicación, con un diseño muy visual y atractivo, presentando la información de una manera amigable para el usuario.","#48b4e1","2"],
    ["socialmedia","insigne","Insigne", "Creación de piezas gráficas para campañas digitales, key visual, racional creativo, mensaje de campaña, copy creativo, generación de formatos óptimos para las diferentes plataformas digitales.","#141414","3"],
    ["branding","cakelab","Cakelab", "Creación y construcción de la marca, naming, logotipo, packaging, identidad visual, recursos gráficos, tipográficos y cromáticos. Así como elementos gráficos para continuar la coherencia de comunicación.","#F495C8","1"],
    ["branding","relajo","Relajo", "Creación y construcción de la marca, naming, logotipo, identidad visual, ilustraciones, lettering, recursos gráficos, tipográficos y cromáticos. Se crearon diseños personalizados para decoración de interiores.","#FF5C3E","2"],
    ["development","diseno","Diseño", "Diseño y desarrollo de plataforma eCommerce con pasarela de pago, creación de catálogo de productos, carretilla de compra, cuenta de usuario, backend de compras, gestor de contenido, buscador, responsive y SEO.","#FC6D25","3"],
    ["development","fyo","Felix & Olivia", "Desarrollo de sitio web 100% personalizado según imagen de la marca con animación parallax, con gestor de contenido, optimizado para todos los dispositivos incluyendo móviles y con indexación de palabras claves (SEO).","#DF3167","4"],
    ["branding","glanz","Glanz", "Creación y construcción de la marca, logotipo, packaging, identidad visual, recursos gráficos, tipográficos y cromáticos. También se creó la línea gráfica para redes sociales, diseño del sitio web y contenido multimedia.","#141414","3"],
    ["branding","villavela","Villavela", "Creación y construcción de la marca, naming, logotipo, identidad corporativa, identidad visual, recursos gráficos, tipográficos y cromáticos. Se desarrolló sitio web, diseño gráfico de redes sociales y contenido multimedia.","#01253C","4"],
    ["socialmedia","kawaki","Kawaki", "Planificación e implementación de estrategia de contenido para redes sociales, creación de línea gráfica de acuerdo a temporada, desarrollo de cronogramas, publicaciones, manejo de la comunidad en línea.","#D51C90","4"],
];

const projectsENG = [
    ["development","atlantida","Atlantida Capital", "Design, creation, and development of an informative website, optimized for all devices, including mobiles (Responsive Design), with content manager and search engine optimization of key words.","#D62934","1"],
    ["socialmedia","coopertires","Coopertires", "Planification and implementation of content strategies for social media, creation of graphic pieces, design of publication timetable, management of online community and digital campaigns.","#1784CB","1"],
    ["socialmedia","repsol","Repsol", "Planification and implementation of content strategies for social media, creation of graphic pieces, design of publication timetable, management of online community and digital campaigns.","#FAA228","2"],
    ["development","uni","Uni", "Designed, created, and developed an instructive website, generating an attractive visual design that allows the user to get all the information necessary to use the service provided.","#48b4e1","2"],
    ["socialmedia","insigne","Insigne", "Creation of graphic pieces for digital campaigns, visual key strategy, creative rational and copy, campaign message, generation of optimal formats for different digital platforms.","#141414","3"],
    ["branding","cakelab","Cakelab", "Creation and construction of the brand, naming, logo, packaging, visual identity, graphic, typographic, and chromatic resources.","#F495C8","1"],
    ["branding","relajo","Relajo", "Creation and construction of the brand, naming, logo, packaging, visual identity, illustrations, lettering, graphic, typographic, and chromatic resources. We personalized the interior design according to it’s corporate image.","#FF5C3E","2"],
    ["development","diseno","Diseño", "Design and development of eCommerce platform with payment gateway, creation of products catalog, shopping cart, user account, shopping backend, content manager, search engine, responsive and SEO.","#FC6D25","3"],
    ["development","fyo","Felix & Olivia", "100% personalized website development according to brand’s image with parallax animation, with content manager, optimized for all devices, including mobile, with indexation of key words (SEO).","#DF3167","4"],
    ["branding","glanz","Glanz", "Creation and construction of the brand, logo, packaging, visual identity, graphic, typographic, and chromatic resources. We also created a graphic line for social media, website design and multimedia content.","#141414","3"],
    ["branding","villavela","Villavela", "Creation and construction of the brand, logo, packaging, visual identity, graphic, typographic, and chromatic resources. We also created a graphic line for social media, website design and multimedia content.","#01253C","4"],
    ["socialmedia","kawaki","Kawaki", "Planification and implementation of content strategies for social media, graphic line creation according to the season, creation of timetable, publications, and online community management.","#D51C90","4"],
];

const langText = {
    es: [
        "Somos una agencia digital que nació en el 2008 al detectar la necesidad de apoyar a las marcas a hacer una transición exitosa a la era digital.",
        services,
        projects,
        "Dentro de la Fábrica encontrarás un mundo de posibilidades, diversidad de pensamiento, pero sobre todo, pasión para brindar soluciones mercadológicas integrales.",
        "Nuestro equipo es multidisciplinario y vive cada marca con la que trabajamos.",
        "Especialista en mercadeo, creador de: Revista 4Men, SV2NIGHT, E! Special El Salvador, precursor de conciertos electrónicos y fundador de La Fábrica.",
        "Economista, dinámica, estratega, orientada a resultados. Conocedora de la lectura e interpretación de estadísticas. Co-fundadora de La Fábrica.",
        "Especialista en Inteligencia de Mercado para la detección de oportunidades que agreguen valor en el mercado centromericano y Panama.",
        "Experiencia en marketing, trade marketing y ventas. Amplio conocimiento en la industria de consumo masivo, desarrollando marcas para de los diferentes canales de distribución.",
        "Experiencia en e-commerce, mercadeo y ventas en empresas como Grupo Q, Verizon y Avianca para Centro y Norte América. Enfocada en mercado online y potenciar ventas en redes sociales.",
        "Elegir país",
        "Nombre Completo",
        "Teléfono",
        "Mensaje",
        "Gracias por escribirnos",
        "Enviar",
        "Edificio Insigne, Oficina 1703, Avenida Las Magnolias, Colonia San Benito, San Salvador.",
        "VER DIRECCIÓN",
        "Estados Unidos",
        "Copyright © 2020 Derechos Reservados",
        "Url de tu portafolio",
        "Tu Portafolio",
        "Tu Resumen",
        "Job",
        "Application",
        "Please complete the form below to apply for a position with us.",
    ],
    en: [
        "We’re a digital agency, founded in 2008 when we noticed the need to assess companies with a successful transition into the digital era.",
        servicesENG,
        projectsENG,
        "In La Fabrica, you’ll find a world of possibilities, diversity of thought, but above all, passion to give our clients comprehensive marketing solutions.",
        "Our team is multidisciplinary and is inspired by every brand we work with.",
        "Maketing specialist, creator of: 4Men Magazine, SV2NIGHT, E! Special El Salvador, pioneer of electronic music concerts, and founder of La Fabrica.",
        "Economist, dynamic strategist, goal oriented. Knowledgeable in reading and interpretation of statistics. Co-founder of La Fabrica.",
        "Market intelligence specialist for the detection of opportunities that add value in Central America and Panama.",
        "Experience in marketing, trade marketing, and sales. Vast knowledge in the mass consumption industry, developing brands for different distribution channels.",
        "Experience in eCommerce, marketing and sales in companies such as Verizon and Avianca for Central and North America. Focused on online markets and boost sales through social media.",
        "Country",
        "Name",
        "Phone",
        "Message",
        "Thanks for writing to us",
        "Send",
        "Edificio Insigne, Oficina 1703, Avenida Las Magnolias, Colonia San Benito, San Salvador.",
        "SEE ADDRESS",
        "United States",
        "Copyright © 2020 All Rights Reserved",
        "Url of your portfolio",
        "Your Portfolio",
        "Your Resume",
        "Job",
        "Application",
        "Please complete the form below to apply for a position with us.",
    ],
}

// console.log(langText["es"][1]);
// console.log(langText[this.state.languages][1]);

class Home extends Component{

    constructor(props){
        super(props);

        this.state = {
            video: (window.innerWidth > 1024)? videoFull : videoMb ,
            service: null,
            project: null,

            project_open: null,
            
            current_service: null,
            current_project: 1,
            img_project: require("../../assets/images/works/projects/cakelab.jpg"),
            current_color: null,

            languages: this.urlLanguages(),
        }
    }

    urlLanguages(){
        var url = (window.location.href).split("/");
        var lang = null;
        
        console.log(url.length);

        if(url.length > 4){
            lang = String(url[3]);
        }else{
            lang = "es";
        }
        
        return lang;
    }

    serviceActive(name){
        this.setState({service: name});
    }

    projectActive(name){
        this.setState({project: name});
    }

    openProject(service,project,position,color){

        if(service.length > 0 && project === null && position === null && color === null){
            projects.forEach((element,index) => {
                if(service === element[0]){
                    if(parseInt(element[5]) === 1){
                        project = element[1];
                        position = element[5];
                        color = element[4];
                    }
                }
            });
        }else if(service.length > 0 && project.length > 0 && position === null && color === null){ //jquery error with map()
            projects.forEach((element,index) => {
                if(service === element[0]){
                    if(project === element[1]){
                        position = element[5];
                        color = element[4];
                    }
                }
            });
        }

        this.setState({current_service: service});
        this.setState({current_project: position});
        this.setState({project_open: project});
        this.setState({img_project: require("../../assets/images/works/projects/"+project+".jpg")});
        this.setState({current_color: color});
    }

    navProject(current,direction){
        var position = null;
        var count = null;

        if(this.state.current_service === "all"){

            count = projects.length - 1;

            if(direction === "right"){
                position = parseInt(current) + 1;
                
                if(position > count)
                    position = 0;

            }else{
                position = parseInt(current) - 1;

                if(position < 0)
                    position = count;
            }

            this.setState({current_project: position});
    
            projects.forEach((project,index) => {
                if(position === index){

                    this.setState({current_color: project[4]});
                    
                    setTimeout(() => {
                        this.setState({img_project: require("../../assets/images/works/projects/"+project[1]+".jpg")}); 
                        this.setState({project_open: project[1]});  
                    }, 200);
                }
             });

        }else{

            projects.forEach(project => {
                if(this.state.current_service === project[0])
                    count+=1;
            });
    
            projects.forEach(project => {
                if(this.state.current_service === project[0]){
                    if(parseInt(current) === parseInt(project[5])){
                        if(direction === "right"){
                            position = parseInt(project[5]) + 1;
                            
                            if(position > count)
                                position = 1;
    
                        }else{
                            position = parseInt(project[5]) - 1;
    
                            if(position < 1)
                                position = count;
                        }
    
                        this.setState({current_project: position});
                    }
                }
            });
    
            projects.forEach(project => {
                if(this.state.current_service === project[0]){
                    if(parseInt(position) === parseInt(project[5])){
                        this.setState({current_color: project[4]});
                        setTimeout(() => {
                            this.setState({img_project: require("../../assets/images/works/projects/"+project[1]+".jpg")});
                            this.setState({project_open: project[1]});  
                        }, 200);
                    }
                }
             });
        }
    }

    componentDidMount(){

        var imageList = [pdek1,pdek2,pdek3,pdek4,pdek5,pdek6,pdek7,pdek8,pdek9,pdek10,pdek11,pdek12];
        imageList.forEach((image) => {
            new Image().src = image
        });

        // var baseUrl = "https://lafabrica.agency/";
        var baseUrl = "https://lafabrica.agency/en/";

        // Languages

        $(".services").find(".layout[section=title]").find(".titles").find(".item").hover(function () {
                
                var service = $(this).find("h2").attr("service");

                $(this).parent().find(".item").each(function (index, element) {
                    $(this).removeClass("on");
                });
                $(this).addClass("on");

                $(".services").find(".layout[section=title]").find(".titles").attr("data-title", service);
                $(".services").find(".layout[section=title]").find(".background").attr("data-background", service);
                
                $(".services").find(".layout[section=title]").find(".background").find(".object").find(".svg").each(function (index, element) {
                    $(this).removeClass("on");
                    if($(this).attr("data-service") === service){
                        $(this).addClass("on");
                    }
                });
                
            }, function () {
                $(this).find("ul").removeClass("on");
            }
        );

        $(".services").find(".layout[section=title]").find(".titles").find("h2").hover(function () {
                $(this).parent().find("ul").addClass("on");
                
            }, function () {

            }
        );

        $(".services").find(".layout[section=title]").find(".titles").on("click","h2", function () {
            var service = $(this).attr("service");
            var section = $(this).parents(".layout").attr("section");

            $(".services").removeClass("fp-section");

            serviceSection(section, service);
        });

        $(".services").find(".layout[section=description]").find(".previews").on("click",".item", function () {
            var service = $(this).parents(".layout").attr("data-service");
            var section = $(this).parents(".layout").attr("section");
            var project = $(this).attr("project");

            serviceSection(section, service, project);
        });

        $(".services").find(".layout[section=projects]").find(".previews").on("click",".item", function () {
            var service = $(this).parents(".layout").attr("data-service");
            var section = $(this).parents(".layout").attr("section");
            var project = $(this).attr("project");

            serviceSection(section, service, project);
        });

        function serviceSection(section, service, project){
            $(".services").find(".layout").removeClass("active");

            if(section === "title"){
                $(".services").find(".layout").eq(0).removeClass("out");
                $(".services").find(".layout").eq(1).addClass("active");
                $(".services").find(".layout").eq(1).attr("data-service", service);

                $(".services").find(".back").removeClass("branding development socialmedia").addClass(service);

                $(".services").attr("active", "description");
            }else if (section === "description"){
                $(".services").find(".layout").eq(0).addClass("out");
                $(".services").find(".layout").eq(1).addClass("out hidden");
                $(".services").find(".layout").eq(2).addClass("active");

                $(".services").attr("active", "projects");
                $(".services").find(".layout").eq(2).attr("data-service", service);
                $(".services").find(".layout").eq(2).attr("data-current-project", project);
                
                $(".services").find(".layout").eq(2).find(".previews").find(".item").each(function (index, element) {
                    $(this).removeClass("on");

                    if($(this).attr("project") === project)
                        $(this).addClass("on");
                });
            }else if(section === "projects"){
                $(".services").find(".layout").eq(2).addClass("active");

                $(".services").find(".layout").eq(2).attr("data-current-project", project);

                $(".services").find(".layout").eq(2).find(".previews").find(".item").each(function (index, element) {
                    $(this).removeClass("on");

                    if($(this).attr("project") === project)
                        $(this).addClass("on");
                });
            }
        }

        $(".services").on("click", ".back", function () { //aqui
            
            $(".services").find(".layout.active").removeClass("active");            
            $(".services").attr("active", "title");
            $(".services").find(".layout").eq(0).removeClass("out").addClass("active");
            $(".services").find(".layout").eq(1).removeClass("out");
            setTimeout(() => {
                $(".services").find(".layout").eq(1).removeClass("hidden");
            }, 1000);

            setTimeout(() => {
                $(".services").removeClass("active").addClass("fp-section active");
            }, 500);
            
        });


        // MODAL
        $(".open-modal").on("click", function () {

            $("#modal-project").addClass("active");
            
            // $("body").addClass("fp-viewing-project");

            $("#parallax").find(".section.active").removeClass("fp-section");

            setTimeout(() => {
                $("#modal-project").addClass("stop");
                setTimeout(() => {
                    $("#modal-project").addClass("full");
                    setTimeout(() => {
                        $("#modal-project").find(".contain").addClass("on");
                    }, 1000);
                }, 500);
            }, 500);

            if($(window).width() < 1024){
                // window.location.href = window.location.href+"/#"+$(this).attr("project");
                window.location.href = baseUrl+"#"+$(this).attr("project");
            }
        });

        $("#modal-project").on("click",".nav", function () {
            $("#modal-project").find(".contain").addClass("opacity");
            setTimeout(() => {
                $("#modal-project").find(".contain").scrollTop(0);
            }, 100);
            setTimeout(() => {
                $("#modal-project").find(".contain").removeClass("opacity");
            }, 600);
        });
    
        $("#modal-project").on("click",".hoverClose", function () { 
            
            $("#modal-project").addClass("out");
            
            setTimeout(() => {
                $("#modal-project").find(".contain").scrollTop(0);
                
                $("#modal-project").removeClass("full");
                $("#modal-project").find(".contain").removeClass("on");
                setTimeout(() => {
                    $("#modal-project").removeClass("stop");
                    setTimeout(() => {
                        $("#modal-project").addClass("zoomIn");
                        setTimeout(() => {
                            $("#modal-project").removeClass("active out zoomIn");
                        }, 500);
                    }, 500);
                }, 500);
            }, 500);

            // $("#parallax").find(".section").each(function (index, element) {
            //     if(!$(this).hasClass("fp-section"))
            //         $(this).removeClass("active").addClass("fp-section active");
                
            // });

            var nowPlaying = $("#modal-project").find("iframe").attr('src');
            $("#modal-project").find("iframe").attr('src',nowPlaying);
        });


        $(".open-modal-join").on("click", function () {

            $("#modal-join").addClass("active");
            
            $("body").addClass("fp-viewing-project");

            // $("#parallax").find(".section.active").removeClass("fp-section");

            setTimeout(() => {
                $("#modal-join").addClass("stop");
                setTimeout(() => {
                    $("#modal-join").addClass("full");
                    setTimeout(() => {
                        $("#modal-join").find(".contain").addClass("on");
                    }, 1000);
                }, 500);
            }, 500);
        });


        $("#modal-join").on("click",".hoverClose", function () { 
            
            $("#modal-join").addClass("out");
            
            setTimeout(() => {
                $("#modal-join").find(".contain").scrollTop(0);
                
                $("#modal-join").removeClass("full active zoomIn");

                $("#modal-join").find(".contain").removeClass("on");
                setTimeout(() => {
                    $("#modal-join").removeClass("stop");
                    setTimeout(() => {
                        // $("#modal-join").addClass("zoomIn");
                        setTimeout(() => {
                            $("#modal-join").removeClass("out");
                        }, 500);
                    }, 500);
                }, 500);
            }, 500);
        });
        
        $(".scroll-bar").hover(function () {
            
            $(".work").removeClass("fp-section");
            
            }, function () {
                $(".work").removeClass("active").addClass("fp-section active");
            }
        );

        
        function urlSearchParams(){
            var url = (window.location.href).split("page=");
            
            if(url.length == 2){
                if(url[1] == "join"){
                    $("#open-modal-join-ft").trigger("click");
                }
            }
        }

        urlSearchParams();

        // CONTACT
        $(".contact").find("form").find(".input").hover(function () {
                if($(this).find("input").val().length > 0){
                    $(this).addClass("on");
                }
            }, function () { }
        );
        

        $(".contact").find("form").find("input").keyup(function() {
            // console.log($(this).val().length);
            if($(this).val().length > 0){
                $(this).parent(".input").addClass("on");
            }else{
                $(this).parent(".input").removeClass("on");
            }
        });


        $("#form-contact").submit(function(e){
            e.preventDefault();

            var country = ($(this).find("select").val().length > 0)? $(this).find("select").val() : " --- ";
            var subject = $(this).find("input[name=subject]").val();
            var name = $(this).find("input[name=name]").val();
            var email = $(this).find("input[name=email]").val();
            var phone = $(this).find("input[name=phone]").val();
            var message = ($(this).find("input[name=message]").val().length > 0)? $(this).find("input[name=message]").val() : " --- ";

            $.ajax({
                type: "POST",
                url: "https://lafabrica.agency/submit/ajax.php",
                // url: process.env.PUBLIC_URL+"submit/ajax.php",
                // url: "http://localhost/lafabrica/web/public/submit/ajax.php",
                data: {
                    subject: subject,
                    country: country,
                    name: name,
                    email: email,
                    phone: phone,
                    message: message,
                },
                dataType: "html",
            }).done(function( response ) {
                console.log(response);
                if(response === "send"){
                    $("#form-contact")[0].reset();
                    
                    $("#form-contact").find(".input").each(function (index, element) {
                        $(this).removeClass("on");
                    });

                    $(".response").addClass("on");
                    setTimeout(() => {
                        $(".response").removeClass("on");
                    }, 5000);
                }
            });
            
        });

        $(window).on('load', function () {
            setTimeout(() => {
                $(".preload").addClass("out");
                $(".background-video")[0].play();
                setTimeout(() => {
                    $(".preload").removeClass("out active");
                }, 1000);
            }, 3000);
        });

        // FORM JOIN

        $("#modal-join").find("form").find("input").keyup(function() {
            // console.log($(this).val().length);
            if($(this).val().length > 0){
                $(this).parent(".input").addClass("on");
            }else{
                $(this).parent(".input").removeClass("on");
            }
        });

        $("#modal-join").find("form").find(".input").on("click",".btn", function () {
            $(this).addClass("on");
        });

        function joinSubmit(e) {
            if (e.type == "submit") {
              e.preventDefault();
            }
            var fd = new FormData(this)
            // console.log(fd);

            $("#form-join").find("button").addClass("on");
        
            $.ajax({
                url: "https://lafabrica.agency/submit/join.php",
                type: "POST",
                data: fd,
                processData: false, // tell jQuery not to process the data
                contentType: false, // tell jQuery not to set contentType,
                complete: function(response) {
                    console.log(response.responseText);
                    
                    if(response.responseText === "send"){
                        $("#form-join")[0].reset();
                        
                        $("#form-join").find(".input").each(function (index, element) {
                            $(this).removeClass("on");
                            $(this).find(".btn").removeClass("on");
                            $("#form-join").find("button").removeClass("on");
                        });

                        $(".response").addClass("on");
                        setTimeout(() => {
                            $(".response").removeClass("on");
                            
                            document.getElementById('jclose').click();
                        }, 4000);
                    }
                },
                error: function() {
                    console.log("error");
                },
            });
        
            return false;
          }
        
          document.getElementById("form-join").addEventListener("submit", joinSubmit, false);
    }
    
    render(){

        const defaultOptionsB = {
            loop: true,
            autoplay: true, 
            animationData: dataBranding.default,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice'
            }
        };

        const defaultOptionsD = {
            loop: true,
            autoplay: true, 
            animationData: dataDevelopment.default,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice'
            }
        };

        const defaultOptionsS = {
            loop: true,
            autoplay: true, 
            animationData: dataSocialmedia.default,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice'
            }
        };

        return(
            <div className="page home" id="home">
                <div className="foreground">
                    <div id="parallax">
                        <div className="section think-digital hoverDark" id={(window.innerWidth < 1024)? "think-digital" : null } >
                            <div className="content">

                                <video autoPlay loop muted playsInline className="background-video">
                                    <source src={this.state.video} type="video/mp4" />
                                </video>
                            
                                <div className="center easeOutCubic-s">
                                    <div className="text">
                                        <h1 className="easeOutCubic-f">Think<strong>Digital</strong></h1>
                                        <p>{langText[this.state.languages][0]}</p>
                                    </div>
                                </div>
                                
                                <div className="layouts">
                                    <div className="item easeInOutQuint"></div>
                                    <div className="item easeInOutCirc"></div>
                                    {/* <div className="item easeInOutExpo"></div> */}
                                </div>

                                <div className="scroll-down easeInOutExpo">
                                    <div className="container">
                                        <div className="chevron"></div>
                                        <div className="chevron"></div>
                                        <div className="chevron"></div>   
                                    </div>
                                    <span>Scroll<i></i>Down</span>
                                </div>

                            </div>
                        </div>
                        
                        <div className="section services" active="title" id={(window.innerWidth < 1024)? "services" : null }>
                        { window.innerWidth > 1024 &&  

                            <div className="content easeInOutCirc">

                                <div className="layout easeInOutCirc active" section="title"> {/* TITLE */}
                                    <div className="center">
                                        <div className="titles" data-title="branding">

                                            {langText[this.state.languages][1].map((service, index) => (
                                                <div className={(index === 0) ? "item easeOutCubic-s on" : "item easeOutCubic-s"} key={index}>
                                                    <h2 className="hoverAction" onClick={() => this.serviceActive(service[0])} service={service[0]}>{service[4]}<i className="fas fa-plus-circle"></i></h2>
                                                    {/* <ul>{ service[2] }</ul> */}
                                                    <ul className="easeOutCubic-f" dangerouslySetInnerHTML={{__html: service[2]}} />
                                                </div>
                                            ))}

                                        </div>
                                        <div className="background" data-background="branding">
                                            <div className="object easeInOutCirc">
                                                
                                                <div className="svg on" data-service="branding"> <Lottie options={defaultOptionsB} isStopped={false} isPaused={false} /> </div>
                                                <div className="svg" data-service="development"> <Lottie options={defaultOptionsD} isStopped={false} isPaused={false} /> </div>
                                                <div className="svg" data-service="socialmedia"> <Lottie options={defaultOptionsS} isStopped={false} isPaused={false} /> </div>
                                                
                                            </div>
                                            <div className="object easeInOutCirc"></div>
                                            <div className="object easeInOutCirc"></div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="layout easeInOutCirc" section="description" data-service="socialmedia"> {/* DESCRIPTION */}
                                    <div className="center">
                                        <div className="image"></div>

                                        <p>{langText[this.state.languages][1].map((service, index) => (
                                            (service[0] === this.state.service) ? service[1] : null
                                        ))}</p>

                                        <a href={`${process.env.PUBLIC_URL}/#services`} className="hoverAction open-modal" service={this.state.service} onClick={() => this.openProject(this.state.service,null,null,null)}>View Projects</a>
                                        
                                        <div className="previews">
                                            {langText[this.state.languages][2].map((project, index) => (
                                                (project[0] === this.state.service)
                                                ? <div className="item" project={project[1]} key={index} onClick={() => this.projectActive(project[1])} ><div className="prevw easeOutCubic-f"></div></div>
                                                : null
                                            ))}
                                        </div>

                                    </div>
                                </div>
                                
                                <div className="layout easeInOutCirc" section="projects"> {/* PROJECTS */}
                                    <div className="center">

                                        <div className="background">
                                            <div className="box easeInOutCirc">
                                                <div className="object"></div>
                                            </div>
                                            <div className="nav">
                                                <div className="center">
                                                    <div className="logo"></div>
                                                    
                                                    <div className="previews">
                                                        {langText[this.state.languages][2].map((project, index) => (
                                                            (project[0] === this.state.service)
                                                            ? <div className="item" project={project[1]} key={index} onClick={() => this.projectActive(project[1])} ><div className="prevw easeOutCubic-f"></div></div>
                                                            : null
                                                        ))}
                                                    </div>

                                                </div>
                                            </div>
                                            
                                        </div>

                                        <div className="information">

                                            {langText[this.state.languages][2].map((project, index) => (
                                                (project[1] === this.state.project) 
                                                ? <div key={index}><h2>{project[2]}</h2><p>{project[3]}</p></div>
                                                : null
                                            ))}

                                            <a href={`${process.env.PUBLIC_URL}/#services`} className="hoverAction open-modal" service={this.state.service} onClick={() => this.openProject(this.state.service,this.state.project,null,null)}>View Project</a>

                                        </div>

                                    </div>
                                </div>
                                
                                <div className="back easeInOutExpo-s hoverAction"><i className="fas fa-undo-alt"></i></div>
                            </div>
                        
                        }
                        { window.innerWidth <= 1024 &&

                            <div className="contentMob easeInOutCirc">

                                <div className="serviceMob" service="branding" id="branding">
                                    <div className="backgroundMob">
                                        <div className="svg" data-service="branding"> <Lottie options={defaultOptionsB} isStopped={false} isPaused={false} /> </div>
                                        <div className="layoutMob"></div>
                                    </div>
                                    <div className="informationMob">
                                        <div className="title"></div>
                                        <p>{ langText[this.state.languages][1][0][1] }</p>
                                        <ul className="easeOutCubic-f" dangerouslySetInnerHTML={{__html: langText[this.state.languages][1][0][2]}} />
                                        <a href={`${process.env.PUBLIC_URL}/#cakelab`} className="hoverAction open-modal" service="branding" project="cakelab" onClick={() => this.openProject("branding","cakelab",null,null)}>View Projects</a>
                                    </div>
                                </div>

                                <div className="serviceMob" service="development" id="development">
                                    <div className="backgroundMob">
                                        <div className="svg" data-service="development"> <Lottie options={defaultOptionsD} isStopped={false} isPaused={false} /> </div>
                                        <div className="layoutMob"></div>
                                    </div>
                                    <div className="informationMob">
                                        <div className="title"></div>
                                        <p>{ langText[this.state.languages][1][1][1] }</p>
                                        <ul className="easeOutCubic-f" dangerouslySetInnerHTML={{__html: langText[this.state.languages][1][1][2]}} />
                                        <a href={`${process.env.PUBLIC_URL}/#atlantida`} className="hoverAction open-modal" service="development" project="atlantida" onClick={() => this.openProject("development","atlantida",null,null)}>View Projects</a>
                                    </div>
                                </div>

                                <div className="serviceMob" service="socialmedia" id="socialmedia">
                                    <div className="backgroundMob">
                                        <div className="svg" data-service="socialmedia"> <Lottie options={defaultOptionsS} isStopped={false} isPaused={false} /> </div>
                                        <div className="layoutMob"></div>
                                    </div>
                                    <div className="informationMob">
                                        <div className="title"></div>
                                        <p>{ langText[this.state.languages][1][2][1] }</p>
                                        <ul className="easeOutCubic-f" dangerouslySetInnerHTML={{__html: langText[this.state.languages][1][2][2]}} />
                                        <a href={`${process.env.PUBLIC_URL}/#coopertires`} className="hoverAction open-modal" service="socialmedia" project="coopertires" onClick={() => this.openProject("socialmedia","coopertires",null,null)}>View Projects</a>
                                    </div>
                                </div>

                            </div>   

                        }
                        </div>
                        
                        <div className="section work" id={(window.innerWidth < 1024)? "work" : null }>
                            <div className="content">
                                <div className="title">
                                    <h2>Our Work</h2>
                                    <p>{langText[this.state.languages][3]}</p>
                                </div>
                                <div className="works scroll-bar">
                                    <div className="box">
                                    {langText[this.state.languages][2].map((project, index) => (
                                        <div className="item open-modal" project={project[1]} key={index} onClick={() => this.openProject("all",project[1],index,project[4])}>
                                            <div className="box easeInOutExpo-f hoverAction">
                                                <div className="object easeInOutExpo"></div>
                                            </div>
                                        </div>
                                    ))}
                                    </div>
                                </div>
                            </div>   
                        </div>
                        
                        <div className="section team" id={(window.innerWidth < 1024)? "team" : null }>
                            <div className="content">
                                <div className="title">
                                    <h2>Our Team</h2>
                                    <p>{langText[this.state.languages][4]}</p>
                                </div>

                                { window.innerWidth > 1024 &&
                                <div className="person">
                                    <div className="item" person="gerardo">
                                        <div className="background easeOutCubic-f">
                                            <div className="object easeOutCubic-l"></div>
                                        </div>
                                        <div className="description">
                                            <h3>Gerardo Liévano</h3>
                                            <h4>CEO</h4>
                                            <p>{langText[this.state.languages][5]}</p>
                                        </div>
                                    </div>
                                    <div className="item" person="daniela">
                                        <div className="background easeOutCubic-f">
                                            <div className="object easeOutCubic-l"></div>
                                        </div>
                                        <div className="description">
                                            <h3>Daniella de Liévano</h3>
                                            <h4>El Salvador</h4>
                                            <p>{langText[this.state.languages][6]}</p>
                                        </div>
                                    </div>
                                    <div className="item" person="alexandra">
                                        <div className="background easeOutCubic-f">
                                            <div className="object easeOutCubic-l"></div>
                                        </div>
                                        <div className="description">
                                            <h3>Alexandra de Iglesias</h3>
                                            <h4>Panamá</h4>
                                            <p>{langText[this.state.languages][7]}</p>
                                        </div>
                                    </div>
                                    <div className="item" person="andres">
                                        <div className="background easeOutCubic-f">
                                            <div className="object easeOutCubic-l"></div>
                                        </div>
                                        <div className="description">
                                            <h3>Andrés Zaldivar</h3>
                                            <h4>{langText[this.state.languages][18]}</h4>
                                            <p>{langText[this.state.languages][8]}</p>
                                        </div>
                                    </div>
                                    <div className="item" person="mili">
                                        <div className="background easeOutCubic-f">
                                            <div className="object easeOutCubic-l"></div>
                                        </div>
                                        <div className="description">
                                            <h3>Natalie Castorino</h3>
                                            <h4>Guatemala</h4>
                                            <p>{langText[this.state.languages][9]}</p>
                                        </div>
                                    </div>
                                </div>
                                }
                                {window.innerWidth <= 1024 &&
                                    <OwlCarousel ref="car" options={options} >
                                        <div className="item" person="gerardo">
                                            <div className="background easeOutCubic-f">
                                                <div className="object easeOutCubic-l"></div>
                                            </div>
                                            <div className="description">
                                                <h3>Gerardo Liévano</h3>
                                                <h4>CEO</h4>
                                                <p>{langText[this.state.languages][5]}</p>
                                            </div>
                                        </div>
                                        <div className="item" person="daniela">
                                            <div className="background easeOutCubic-f">
                                                <div className="object easeOutCubic-l"></div>
                                            </div>
                                            <div className="description">
                                                <h3>Daniella de Liévano</h3>
                                                <h4>El Salvador</h4>
                                                <p>{langText[this.state.languages][6]}</p>
                                            </div>
                                        </div>
                                        <div className="item" person="alexandra">
                                            <div className="background easeOutCubic-f">
                                                <div className="object easeOutCubic-l"></div>
                                            </div>
                                            <div className="description">
                                                <h3>Alexandra de Iglesias</h3>
                                                <h4>Panamá</h4>
                                                <p>{langText[this.state.languages][7]}</p>
                                            </div>
                                        </div>
                                        <div className="item" person="andres">
                                            <div className="background easeOutCubic-f">
                                                <div className="object easeOutCubic-l"></div>
                                            </div>
                                            <div className="description">
                                                <h3>Andrés Zaldivar</h3>
                                                <h4>{langText[this.state.languages][18]}</h4>
                                                <p>{langText[this.state.languages][8]}</p>
                                            </div>
                                        </div>
                                        <div className="item" person="mili">
                                            <div className="background easeOutCubic-f">
                                                <div className="object easeOutCubic-l"></div>
                                            </div>
                                            <div className="description">
                                                <h3>Natalie Castorino</h3>
                                                <h4>Guatemala</h4>
                                                <p>{langText[this.state.languages][9]}</p>
                                            </div>
                                        </div>
                                    </OwlCarousel>
                                }
                                <div  className="join open-modal-join hoverAction"><p>¿Quieres <strong>unirte</strong> a nuestro equipo?</p></div>
                            </div>
                        </div>
                        
                        <div className="section contact hoverDark" id={(window.innerWidth < 1024)? "contact" : null }>
                            <div className="content">
                                <div className="center">
                                    <div className="form">
                                        <h2>Contact Us</h2>
                                        <form action="#no-submit" method="post" id="form-contact">
                                            <select name="country" required>
                                                <option hidden>{langText[this.state.languages][10]}</option>
                                                <option value="El Salvador">El Salvador</option>
                                                <option value="Estados Unidos">{langText[this.state.languages][18]}</option>
                                                <option value="Guatemala">Guatemala</option>
                                                <option value="Panamá">Panamá</option>
                                            </select>
                                            <div className="input">
                                                <label className="easeInOutExpo-f">{langText[this.state.languages][11]}</label>
                                                <input type="text" name="subject" placeholder="Subject" />
                                                <input type="text" name="name" required/>
                                            </div>
                                            <div className="input">
                                                <label className="easeInOutExpo-f">{langText[this.state.languages][12]}</label>
                                                <input type="tel" name="phone" required/>
                                            </div>
                                            <div className="input">
                                                <label className="easeInOutExpo-f">@mail.com</label>
                                                <input type="email" name="email" required/>
                                            </div>
                                            <div className="input">
                                                <label className="easeInOutExpo-f">{langText[this.state.languages][13]}</label>
                                                <input type="text" name="message" />
                                            </div>
                                            <h3 className="response"><i className="fas fa-check-double"></i> {langText[this.state.languages][14]}</h3>
                                            <button>{langText[this.state.languages][15]}</button>
                                        </form>
                                    </div>
                                    <div className="information">
                                        <h3>HQ <strong>El Salvador</strong></h3>
                                        <a href="tel:+ (503) 2541-4232" className="tel">+ (503) 2541-4232</a>
                                        <p>{langText[this.state.languages][16]}</p>
                                        <a href="https://www.waze.com/ul?place=ChIJTTrp8i8wY48RaRnozobX1mM&ll=13.69245890%2C-89.23828200&navigate=yes" className="waze hoverAction" target="_blank" rel="noopener noreferrer"><i className="fab fa-waze"></i>{langText[this.state.languages][17]}</a>
                                        <ul>
                                            <li>{langText[this.state.languages][18]}</li>
                                            <li>Guatemala</li>
                                            <li>Panamá</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="copyright">
                                    <p>{langText[this.state.languages][19]}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="modal-project" className="easeOutCubic-s" style={{backgroundColor: this.state.current_color}}>
                {window.innerWidth > 1024 &&
                    <div className="contain easeOutCubic-f">
                        <div className="box">
                            <div className={this.state.project_open === "insigne" ? "link hoverHidden" : "link hoverHidden hidden"}><iframe src={this.state.project_open === "insigne" ? "https://www.youtube-nocookie.com/embed/vNyyC7Q6ZDI?controls=0&rel=0&listType=playlist" : "#null"} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen link="insigne"></iframe></div>
                            <img src={this.state.img_project} alt="project"  className="hoverClose" />
                        </div>
                        <i className="nav right fas fa-angle-right" onClick={() => this.navProject(this.state.current_project,"right")}></i>
                        <i className=" nav left  fas fa-angle-left" onClick={() => this.navProject(this.state.current_project,"left")}></i>
                    </div>
                }
                {window.innerWidth <= 1024 && 
                    <div className="containMob easeOutCubic-f">
                        <div className="hoverClose"><i className="fas fa-times"></i></div>
                        <div className="box">

                            <OwlCarousel ref="mobile" options={optionsAll} >
                                <div className="item" data-hash="cakelab"><img src={pmob6} /></div>
                                <div className="item" data-hash="relajo"><img src={pmob7} /></div>
                                <div className="item" data-hash="glanz"><img src={pmob10} /></div>
                                <div className="item" data-hash="villavela"><img src={pmob11} /></div>
                                <div className="item" data-hash="atlantida"><img src={pmob1} /></div>
                                <div className="item" data-hash="uni"><img src={pmob4} /></div>
                                <div className="item" data-hash="diseno"><img src={pmob8} /></div>
                                <div className="item" data-hash="fyo"><img src={pmob9} /></div>
                                <div className="item" data-hash="coopertires"><img src={pmob2} /></div>
                                <div className="item" data-hash="repsol"><img src={pmob3} /></div>
                                <div className="item" data-hash="insigne">
                                    <div className="link hoverHidden"><iframe src="https://www.youtube-nocookie.com/embed/vNyyC7Q6ZDI?rel=0&listType=playlist" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen link="insigne"></iframe></div> 
                                    <img src={pmob5} />
                                </div>
                                <div className="item" data-hash="kawaki"><img src={pmob12} /></div>
                            </OwlCarousel>

                        </div>
                    </div>
                }
                </div>

                <div id="modal-join" className="easeOutCubic-s">
                    <div className="contain easeOutCubic-f">
                        <div className="box ">
                            <div className="close hoverClose" id="jclose"><i className="fas fa-times"></i></div>
                            <div className='text'>
                                <h2>{langText[this.state.languages][23]} <strong>{langText[this.state.languages][24]}</strong></h2>
                                <p>{langText[this.state.languages][25]}</p>
                            </div>

                            <form action="#no-submit" id="form-join" method="POST" encType="multipart/form-data" >
                                
                                <div className="input">
                                    <label className="easeInOutExpo-f">{langText[this.state.languages][11]}</label>
                                    <input type="text" name="subject" placeholder="Subject" />
                                    <input type="text" name="name" required/>
                                </div>
                                <div className="input">
                                    <label className="easeInOutExpo-f">{langText[this.state.languages][12]}</label>
                                    <input type="tel" name="phone" required/>
                                </div>
                                <div className="input">
                                    <label className="easeInOutExpo-f">@mail.com</label>
                                    <input type="email" name="email" required/>
                                </div>
                                
                                <div className="input">
                                    <label className="easeInOutExpo-f">{langText[this.state.languages][20]}</label>
                                    <input type="url" name="url_portfolio" required />
                                </div>

                                <div className="input two">
                                    
                                    <div className='btn' input='resume' id="ij-res">
                                        <label className="custom-file-upload">
                                            <input type="file" name="resume" accept="application/pdf" />
                                            <i className="far fa-file-pdf"></i> {langText[this.state.languages][22]}
                                        </label>
                                    </div>

                                    <button type="submit"><span>{langText[this.state.languages][15]}</span>  <i className="fas fa-circle-notch fa-spin"></i></button>
                                </div>

                                <h3 className="response"><i className="fas fa-check-double"></i> {langText[this.state.languages][14]}</h3>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Home;