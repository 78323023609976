import React, { Component } from 'react';
// import logo from '../../assets/images/logo.png';

import $ from 'jquery';

class Header extends Component{
    
    componentDidMount(){
        $("#hamburger").on("click", function () {
            $(this).toggleClass("is-active");
            $("#menu").toggleClass("on");
            if($("#menu").hasClass("on")){
                $("body").attr("afterClass",$("body").attr("class"));

                if(window.innerWidth > 1024){
                    $("body").removeAttr("class").addClass("fp-viewing-think-digital");
                }else{
                    $("body").removeAttr("class").addClass("fp-viewing-mobile");
                }
                

                if($("#parallax").find(".work").hasClass("active")){
                    $(".section.work").removeClass("active").addClass("fp-section active");
                }else if($("#parallax").find(".services").hasClass("active")){
                    $(".section.services").removeClass("active").addClass("fp-section active");
                }

                $("#modal-project").removeClass("active stop full");
                $("#modal-project").find(".contain").removeClass("on");

            }else{
                $("body").removeAttr("class").addClass($("body").attr("afterClass"));
            }
        });

        $("#menu").find(".box").find(".item").on("click","a", function () {
            if(window.innerWidth > 1024){
                if($("#parallax").find(".work").hasClass("active")){
                    $(".work").removeClass("active").addClass("fp-section active");
                }else if($("#parallax").find(".services").hasClass("active")){
                    $(".section.services").removeClass("active").addClass("fp-section active");
                }
            }
        
            // window.location.href = $(this).attr("href");

            setTimeout(() => {
                $("#hamburger").toggleClass("is-active");
                $("#menu").toggleClass("on");
                // $("body").removeAttr("class").addClass($("body").attr("afterClass"));
            }, 300);
        });

        $("#social-media").find(".languages").hover(function () {
                $(this).find(".lang").addClass("on");
            }, function () {
                // out
            }
        );

        $("#social-media").on("click",".languages", function () {
            $(this).find(".lang").toggleClass("on");
        });

        $("#social-media").find(".languages").find(".lang").hover(function () {
                // over
            }, function () {
                $(this).removeClass("on");
            }
        );
    }

    render(){
        return(
            <header><div className="logo hoverAction"><a href="/"></a></div><div className="f-hover" id="switch"><i className="fas fa-adjust"></i></div><div id="hamburger" className="hamburger hoverAction hamburger--elastic-r f-hover"><div className="hamburger-box"><div className="hamburger-inner"></div></div></div></header>
        );
    }
}

export default Header;